<template>
  <div class="contailPanel">
    <div class="leftbox" >
      <leftab  
        :title="tabtitle" 
        :menupath="listpath"
        :list="tablist" />
    </div>
    <div class="mainbox" >
      <router-view />
    </div>
  </div>
</template>

<script>
import leftab from "../../components/all/leftab"; //引入二级菜单切换组件

export default {
  name: "news", //信息公告页面
  components: {
    leftab,
  },
  props: {
  },

  // watch:{
  //   $route:{
  //     handler(val,oldval){
  //       console.log(" index route val:" , val);//新路由信息
  //       console.log("      route old:" , oldval);//老路由信息
  //       console.log(" tag ",  this.$route.params.newsTag)
  //     },
  //     // 深度观察监听
  //     deep: true
  //   }
  // }, 

  data() {
    return {
      tabtitle: "新闻公告",
      tablist: [
        {
          title:  "新闻信息",
          value: "0",
        }, {
          title:"平台公告",
          value: "1",
        }
      ],
      listpath: '/news/list/',
      // newsTag: "0",  
    }
  },
  // $route:{
  //   handler(val){
  //     this.newsTag = val.params.newsTag
  //   },
  //   deep: true
  // },
  // created() {
  //   this.init()
  // },
  // methods: {
  //   init(){
  //      this.newsTag = this.$route.params.newsTag
  //   },
  // },
};
</script>

<style scoped lang="less">
// @media screen and (min-width: 1000px) {
//   .detailbox {
//       min-height: 50vh;
//     // 面包屑
//     /deep/ .more {
//       display: none;
//     }
//     /deep/ .cardbox {
//       padding: 0;
//       .cardhead {
//         padding-left: 0.375rem;
//       }
//       .cardcent {
//         margin-top: 1rem;
//         padding-left: 0.375rem;

//       }
//     }
//   }
// }

// @media screen and (max-width: 999px) {
//   .detailbox {
//       min-height: 50vh;
//     // 面包屑
//     /deep/ .more {
//       display: none;
//     }
//     /deep/ .cardbox {
//       padding: 0 .9375rem;
//       .cardhead {
//         padding-left:.675rem;
//       }
//       .cardcent {
//         .hangbox {
//           height: 1.8rem;
//           margin: 0;
//         }
//       }
//     }
//   }
// }
</style>
