<template>
  <div class="lefttab" v-if="list.length > 0">
    <div class="leftheader">{{title}}</div>
    <el-menu v-if="menupath"  
      active-text-color="#333"
      text-color="#666"
      :router=true
      :default-active=activeIndex 
    >
      <el-menu-item :route="{path: menupath+item.value}"  
        v-for="(item, index) in list" :key="index" :index=index.toString()
      >
        <i class="el-icon-menu"></i>
        <span slot="title">{{item.title}}</span>
      </el-menu-item>
    </el-menu>

    <el-menu v-else
      :default-active="activeIndex"
      active-text-color="#333"
      text-color="#666"
      >
      <el-menu-item 
        v-for="(item, index) in list" :key="index"  :index="item.value" >
        <i class="el-icon-menu"></i>
        <span slot="title">{{item.title}}</span>
      </el-menu-item>
    </el-menu>

  </div>
</template>

<script>
export default {
  name: "leftab", //卡片
  props: {
    list: {
      typeof: [Array],
    },
    menupath: {
      typeof: [String],
    },
    title: {
      typeof: [String],
      default: "",
    },
    active: {
      typeof: [String], 
    },
  },
  // created() {
  //   console.log(" leftab active : " , this.active)
  //   console.log(" leftab title : " , this.title)
  // },
  data() {
    return {
      activeIndex: "1",
    };
  },
  
  created() {
    if(this.active)
      this.activeIndex=this.active
    this.init()

  },
     
  watch:{
    $route:{
      handler(){
        // console.log("----------- route path changed  oldval", oldval)
        // this.newsTag = val.params.newsTag
        // this.getlist()
        this.init()
      },
      // 深度观察监听
      deep: true
    }
  }, 
  methods: {
    init(){
      var path = this.$route.path;
      // console.log(" path ", path)
      for(var i =0; i<this.list.length;i++) {
        // console.log(i +"  " + this.list[i].value)
        // console.log( path.indexOf(this.list[i].value) )
        // console.log(  " 0 == " , path.indexOf(this.list[i].value) )
        if(path.indexOf(this.list[i].value)>=0) {
          this.activeIndex = i.toString();
          break;
        }
      }
      // console.log("==== activeIndex ", this.activeIndex)
    },
  
    // // 点击更多时触发
    // linkpage(menuItem) {
    //   // console.log( "----------- click menu ", menuItem )
    //   this.secondary = menuItem;
    //   this.$emit("changenar", menuItem);
    // },
  },
}
</script>


<style scoped lang="less">
  .lefttab {
    // border: 1px solid rgb(14, 6, 49);
    // height: 100%;
    .leftheader{
      line-height: 60px;
      position: relative;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #FFF;
      background: #2575c5;
      border-right: solid 1px #e6e6e6;
    }
    .el-menu-item.is-active {
       background-color: #c7d4f1 !important;
    }
  }
  

// @media screen and (min-width: 1000px) {
//   .box {
//     width: 100%;
//     height: 100%;
//     .modeio{
//       display: none;
//     }
//     // 没一项内容
//     .flexcent {
//       height: 3.5rem;
//       font-size: .875rem;
//       color: #303133;
//     }
//     .flexcent:hover{
//       cursor: pointer;
//       background-color: #ecf5ff;
//     }
//   }
// }

// @media screen and (max-width: 999px) {
//   .box {
//     width: 100%;
//     height: 100%;
//     // 没一项内容
//     .flexcent {
//      display: none;
//     }
//     .modeio{
//       padding-left: 1.25rem;
//     }
//   }
// }
</style>
